import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
    code1238,
    code1317,
    code1432,
    code1632,
    code1815,
    code2478,
    code2697,
    code3123,
    code3169,
    code4111,
    code5322,
    code6659,
    code8863,
    code9549,
    iconUser,
    iconBalance,
    iconReload,
    iconMessage,
    iconRecharge,
    iconWithdraw,
    iconTransfer,
    iconLogout,
} from '../../../../img/importImg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import accountAPI from '../../../../API/account';
import './BottomHeader.scss';
import { Link } from 'react-router-dom';

const VND = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
});

function BottomHeader() {
    const [isReload, setIsReload] = useState(true);
    const [user, setUser] = useState();
    const [randomCode, setRandomCode] = useState();
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [userCode, setUserCode] = useState();
    // username để quản lý name user khi đăng nhập thành công
    const [username, setUsername] = useState(sessionStorage.getItem('username'));

    const codeMap = new Map([
        [code1238, 1238],
        [code1317, 1317],
        [code1432, 1432],
        [code1632, 1632],
        [code1815, 1815],
        [code2478, 2478],
        [code2697, 2697],
        [code3123, 3123],
        [code3169, 3169],
        [code4111, 4111],
        [code5322, 5322],
        [code6659, 6659],
        [code8863, 8863],
        [code9549, 9549],
    ]);

    const handleOnchangeLanguage = (e) => {
        e.preventDefault();
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
    };

    function randomFromArray(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    function getRandomImageAndCode(map) {
        const entries = Array.from(map.entries());
        const randomEntry = randomFromArray(entries);
        return {
            image: randomEntry[0],
            code: randomEntry[1],
        };
    }

    useEffect(() => {
        setRandomCode(getRandomImageAndCode(codeMap));
    }, []);

    useEffect(() => {
        try {
            const fetchGetUserInfo = async () => {
                const result = await accountAPI.getUserInfo();
                // console.log(result);
                setUser(result);
            };

            fetchGetUserInfo();
        } catch (error) {
            return toast.error('Có lỗi xảy ra!', { theme: 'light' });
        }
    }, [isReload]);

    const handleLogin = async (e) => {
        try {
            e.preventDefault();
            if (!userName || !password || !userCode) {
                return toast.error('Không được để trống!', { theme: 'light' });
            } else if (userCode !== String(randomCode.code)) {
                return toast.error('Mã xác nhận không hợp lệ!', { theme: 'light' });
            }

            const data = {
                username: userName,
                password: password,
                userCode: userCode,
            };

            const result = await accountAPI.postLogin(data);
            if (result.status === 'ok') {
                // console.log(result);
                sessionStorage.setItem('auth', result.auth);
                sessionStorage.setItem('username', result.username);

                // localStorage.setItem("auth", result.auth);
                // localStorage.setItem("username", result.username);
                setUsername(result.username);
                toast.success(result.message, { theme: 'light' });

                setTimeout(() => {
                    window.location.href = '/';
                }, 1500);
            } else {
                setRandomCode(getRandomImageAndCode(codeMap));
                return toast.error(result.message, { theme: 'light' });
            }
        } catch (error) {
            return toast.error('Có lỗi xảy ra!', { theme: 'light' });
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('username');
        // localStorage.removeItem("auth");
        // localStorage.removeItem("username");
        setUsername('');

        setTimeout(() => {
            window.location.href = '/';
        }, 1000);
    };

    const reLoad = () => {
        setIsReload(!isReload);
    };

    return (
        <div className="bottom-header">
            <div className="container">
                {username ? (
                    <div className="member-nav">
                        <Link
                            className="member-nav__link"
                            to="/my/info"
                        >
                            <span className="member-nav__account">
                                <img
                                    className=""
                                    src={iconUser}
                                    alt=""
                                />
                            </span>
                            <span className="member-nav__txt username">{username}</span>
                        </Link>

                        <div className="member-nav__link member-nav__link--balance">
                            <img
                                className="member-nav__icon"
                                src={iconBalance}
                                alt=""
                            />
                            <Link className="balance">{user?.money ? VND.format(Number(user?.money)) : '0đ'}</Link>
                            <Link
                                className="member-nav__reload"
                                onClick={reLoad}
                            >
                                <img
                                    src={iconReload}
                                    alt=""
                                />
                            </Link>
                        </div>

                        <Link
                            id="unreadMsg"
                            className="member-nav__link member-nav__link--msg"
                        >
                            <img
                                src={iconMessage}
                                alt=""
                            />
                        </Link>

                        <Link
                            className="member-nav__link member-nav__btn member-nav__link--recharge"
                            to="/support"
                        >
                            <img
                                className="member-nav__icon"
                                src={iconRecharge}
                                alt=""
                            />
                            <span>Nạp tiền</span>
                        </Link>

                        <Link
                            className="member-nav__link member-nav__btn member-nav__link--withdraw"
                            to="/my/withdraw"
                        >
                            <img
                                className="member-nav__icon"
                                src={iconWithdraw}
                                alt=""
                            />
                            <span>Rút tiền</span>
                        </Link>

                        <Link
                            className="member-nav__link member-nav__btn member-nav__link--transfer"
                            to="/my/transfer"
                        >
                            <img
                                className="member-nav__icon"
                                src={iconTransfer}
                                alt=""
                            />
                            <span>Chuyển quỹ</span>
                        </Link>

                        <Link className="member-nav__link member-nav__link--history">
                            <span>KQ Mở thưởng</span>
                        </Link>

                        <Link
                            to="/my/history"
                            className="member-nav__link member-nav__link--betrecord"
                        >
                            <span>Lịch Sử</span>
                        </Link>

                        <Link
                            className="member-nav__link member-nav__btn member-nav__link--logout"
                            onClick={handleLogout}
                        >
                            <img
                                className="member-nav__icon"
                                src={iconLogout}
                                alt=""
                            />
                            <span>Đăng xuất</span>
                        </Link>
                    </div>
                ) : (
                    <div className="box-login">
                        <form
                            className="member-login"
                            onSubmit={handleLogin}
                        >
                            <div className="form-group account">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Tên đăng nhập"
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-group password">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Mật khẩu"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="form-group valid">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Mã xác nhận"
                                    onChange={(e) => {
                                        setUserCode(e.target.value);
                                    }}
                                />
                                <div className="valid-code">
                                    <img
                                        src={randomCode?.image}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn-login login"
                            >
                                Đăng nhập
                            </button>
                            <Link
                                to="/register"
                                className="btn-login signup"
                            >
                                Đăng ký
                            </Link>
                            <Link
                                to="/"
                                className="btn-login try"
                            >
                                Chơi thử
                            </Link>
                            <select
                                id="languages"
                                name="languages"
                                onChange={handleOnchangeLanguage}
                            >
                                <option value="eng">English</option>
                                <option value="vie">Tiếng Việt</option>
                            </select>
                            <Link
                                href="/"
                                className="btn-forget"
                            >
                                Quên mật khẩu
                            </Link>
                        </form>
                    </div>
                )}
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default BottomHeader;
